import React, { FC } from "react";

import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import {
  AttachmentRes,
  RenderEmailRes,
} from "@n3oltd/karakoram.templates.sdk.email/esm";
import { Alert, Button, Col, Row } from "antd";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";

import ItemSummaryRow from "components/admin/shared/ItemSummaryRow";
import { K2Message } from "components/k2Widgets";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import K2Modal from "components/k2Widgets/k2Modal";
import IFrame from "routes/admin/communication/sharedComponents/IFrame";

import themeVariables from "../../../../../common/themeVariables";

interface EmailPreviewProps {
  email: RenderEmailRes;
  loading: boolean;
  errors?: string[];
  onClose: () => void;
  k2Intl: InjectedK2Intl;
  onNext?: () => void;
  onPrevious?: () => void;
  showControls?: boolean;
  isNextAvailable?: boolean;
  isPreviousAvailable?: boolean;
}

const Error = styled.div`
  color: ${({ theme }) => theme.error_color};
`;

const EmailPreview: FC<EmailPreviewProps> = ({
  email,
  onClose,
  loading,
  errors,
  onNext,
  onPrevious,
  isNextAvailable,
  isPreviousAvailable,
  showControls = false,
}) => {
  return (
    <K2Modal
      width={"70%"}
      loading={loading}
      titleKey={"admin.communication.email.preview"}
      onCancel={onClose}
      visible={true}
      footer={null}
      bodyStyle={{ padding: showControls ? "0px" : null }}
    >
      {!showControls && (
        <h3>
          <K2Message localeKey={"admin.communication.email.details"} />
        </h3>
      )}

      {showControls && (
        <Row
          justify="space-between"
          align="middle"
          style={{
            backgroundColor: themeVariables.grey_2,
            maxHeight: "60px",
            height: "60px",
            marginBottom: "30px",
          }}
        >
          <Col span={4}>
            <Button
              type="link"
              disabled={!isPreviousAvailable}
              onClick={onPrevious}
            >
              <LeftOutlined /> <K2Message localeKey={"common.previous"} />
            </Button>
          </Col>
          <Col span={16} style={{ textAlign: "center" }}>
            <div>
              <K2Message localeKey={"admin.communication.email.previewing"} />
            </div>
          </Col>
          <Col span={4} style={{ textAlign: "right" }}>
            <Button type="link" disabled={!isNextAvailable} onClick={onNext}>
              <K2Message localeKey={"common.next"} /> <RightOutlined />
            </Button>
          </Col>
        </Row>
      )}
      {email && (
        <div style={{ paddingLeft: showControls ? "20px" : null }}>
          <div>
            <ItemSummaryRow
              value={
                <>
                  {email?.sender?.name ? (
                    <>
                      <span>{email.sender.name}</span>{" "}
                      <span className={"email-address"}>
                        ({email.sender.email})
                      </span>
                    </>
                  ) : (
                    <span className={"email-address"}>
                      {email?.sender?.email}
                    </span>
                  )}
                </>
              }
              labelKey={"communication.from"}
            />
          </div>

          <div>
            <ItemSummaryRow
              value={
                !email?.attachments?.length ? (
                  <K2Message localeKey={"common.none"} />
                ) : (
                  <div>
                    {email?.attachments?.map?.((a: AttachmentRes) => (
                      <p key={a.storageToken}>{a.name}</p>
                    ))}
                  </div>
                )
              }
              labelKey={"common.attachments"}
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <IFrame content={email.body} />
          </div>
        </div>
      )}
      {errors?.length > 0 && (
        <Col span={24}>
          <Alert
            type="error"
            showIcon
            message={errors?.map?.((e, i) => (
              <p key={i}>{e}</p>
            ))}
          />
        </Col>
      )}
    </K2Modal>
  );
};

export default EmailPreview;
