import { LookupType as AccountLookups } from "@n3oltd/k2.accounts.sdk.lookups/esm";
import { LookupType as CallsLookups } from "@n3oltd/k2.calls.sdk.lookups/esm";
import { LookupType as CommunicationsLookups } from "@n3oltd/k2.communications.sdk.lookups/esm";
import { LookupType as DonationLookups } from "@n3oltd/k2.donations.sdk.lookups/esm";
import { LookupType as PledgeLookups } from "@n3oltd/k2.pledges.sdk.lookups/esm";
import { LookupType as SubscriptionLookups } from "@n3oltd/k2.subscriptions.sdk.lookups/esm";
import { LookupType as UserLookups } from "@n3oltd/k2.users.sdk.lookups/esm";
import { LookupType as AnalyticsLookups } from "@n3oltd/karakoram.analytics.sdk.lookups/esm";
import { LookupType as CrowdFundingLookups } from "@n3oltd/karakoram.crowdfunding.sdk.lookups";
import { LookupType as DataImportLookups } from "@n3oltd/karakoram.dataimport.sdk.lookups/esm";
import { LookupType as EmailLookups } from "@n3oltd/karakoram.emails.sdk.lookups/esm";
import { LookupType as FeedbackLookups } from "@n3oltd/karakoram.feedbacks.sdk.lookups/esm";
import { LookupType as FormsLookups } from "@n3oltd/karakoram.forms.sdk.lookups";
import { LookupType as ListsLookups } from "@n3oltd/karakoram.lists.sdk.lookups/esm";
import { LookupType as MailLookups } from "@n3oltd/karakoram.mail.sdk.lookups/esm";
import { LookupType as PaymentsLookups } from "@n3oltd/karakoram.payments.sdk.lookups/esm";
import { LookupType as PrintLookups } from "@n3oltd/karakoram.print.sdk.lookups/esm";
import { LookupType as ReportLookups } from "@n3oltd/karakoram.reporting.sdk.lookups";
import { LookupType as ReportingLookups } from "@n3oltd/karakoram.reporting.sdk.lookups/esm";
import { LookupType as SMSLookups } from "@n3oltd/karakoram.sms.sdk.lookups/esm";
import { LookupType as SponsorshipLookups } from "@n3oltd/karakoram.sponsorships.sdk.lookups/esm";
import { LookupType as TasksLookups } from "@n3oltd/karakoram.tasks.sdk.lookups/esm";
import { LookupType as TaxReliefLookups } from "@n3oltd/karakoram.taxrelief.sdk.lookups/esm";
import { LookupType as TemplateLookups } from "@n3oltd/karakoram.templates.sdk.lookups/esm";
import { LookupType as TimeClockLookups } from "@n3oltd/karakoram.timeclock.sdk.lookups/esm";
import { LookupType as WebHooksLookups } from "@n3oltd/karakoram.webhooks.sdk.lookups/esm";

import { K2Lookup, Lookups } from "../../modules/lookups/types";

export default class LookupsModel {
  /**Determine the lookups which have not been retrieved */
  static determineLookupsToFetch(
    requested: LookupType[],
    lookups: Lookups,
  ): LookupType[] {
    const now = new Date();
    let requiredLookups: LookupType[] = [];

    requested.forEach((name) => {
      const lookupExpired = now >= new Date(lookups[name]?.expiryTime);

      if (
        !lookups[name] ||
        !lookups[name].items?.length ||
        !lookups[name].expiryTime ||
        lookupExpired
      ) {
        requiredLookups.push(name);
      }
    });

    return requiredLookups;
  }

  /** Returns an object only containing the lookups which are requested by the component */
  static getLookupsToInject(requested: LookupType[], lookups: Lookups) {
    let lookupsToInject: { [K in keyof Lookups]?: K2Lookup<any> } = {};

    requested.forEach((l) => {
      // Added because some backend services return lookup names with capitalized first letter and others don't
      // e.g. DataImportRecordStatuses vs callStatuses
      l = (l[0].toLowerCase() + l.slice(1)) as LookupType;
      lookupsToInject[l] = lookups[l];
    });

    return lookupsToInject;
  }
}

export type MergedLookupType =
  | AccountLookups
  | AnalyticsLookups
  | CallsLookups
  | CommunicationsLookups
  | DataImportLookups
  | DonationLookups
  | EmailLookups
  | FeedbackLookups
  | ListsLookups
  | MailLookups
  | PaymentsLookups
  | PledgeLookups
  | PrintLookups
  | ReportingLookups
  | SMSLookups
  | SponsorshipLookups
  | SubscriptionLookups
  | TaxReliefLookups
  | TemplateLookups
  | UserLookups
  | TasksLookups
  | TimeClockLookups
  | WebHooksLookups
  | FormsLookups
  | CrowdFundingLookups
  | "queues";

/*
 * as all the lookup types are now merged
 * so we can simply set it to merged lookup type
 * and can add additional ones later if needed
 */
export type LookupType = MergedLookupType;

export type MergedLookup =
  | "accountsMerged"
  | "analyticsMerged"
  | "callsMerged"
  | "communicationsMerged"
  | "donationsMerged"
  | "emailAdminMerged"
  | "importsMerged"
  | "listsMerged"
  | "mailMerged"
  | "paymentsMerged"
  | "pledgesMerged"
  | "printMerged"
  | "reportingMerged"
  | "smsMerged"
  | "sponsorshipsMerged"
  | "subscriptionsMerged"
  | "taxReliefMerged"
  | "templatesMerged"
  | "usersMerged"
  | "tasksMerged"
  | "timeclockMerged"
  | "feedbacksMerged"
  | "webhooksMerged"
  | "formsMerged"
  | "crowdfundingMerged";

// If a lookup can be fetched via a merged endpoint, add the mapping here.
type MergedLookupMapType = {
  [key in MergedLookupType]: MergedLookup;
};

export const MergedLookupMap: MergedLookupMapType = {
  //#region Accounts
  accountStatuses: "accountsMerged",
  accountTypes: "accountsMerged",
  attachmentTypes: "accountsMerged",
  closureReasons: "accountsMerged",
  organizationTypes: "accountsMerged",
  titles: "accountsMerged",
  householdStatuses: "accountsMerged",
  //#endregion

  //#region Analytics
  pinningLocations: "analyticsMerged",
  reportCategories: "analyticsMerged",
  measureCategories: "analyticsMerged",
  reportTypes: "analyticsMerged",
  trends: "analyticsMerged",
  touchpointStatuses: "analyticsMerged",
  touchpoints: "analyticsMerged",
  fundraisers: "analyticsMerged",
  fundraiserStatuses: "analyticsMerged",
  reportingPeriods: "analyticsMerged",
  dataScopes: "analyticsMerged",
  //#endregion

  //#region Time Clock
  jobRoles: "timeclockMerged",
  clockInStatuses: "timeclockMerged",
  shiftStatuses: "timeclockMerged",
  timesheetStatuses: "timeclockMerged",
  //#endregion

  //#region Calls
  callAppointmentStatuses: "callsMerged",
  callAssigneeTypes: "callsMerged",
  callDirections: "callsMerged",
  callDispositions: "callsMerged",
  callEndedReasons: "callsMerged",
  callPurposes: "callsMerged",
  callStatuses: "callsMerged",
  callCampaignStatuses: "callsMerged",
  callContributionLevels: "callsMerged",
  callDialingModes: "callsMerged",
  callNextForDialerTypes: "callsMerged",
  callRecordStatuses: "callsMerged",
  callSkipReasons: "callsMerged",
  callRecordSuppressedReasons: "callsMerged",
  callTeamRoles: "callsMerged",
  callTeamStatuses: "callsMerged",
  //#endregion

  //#region Crowdfunding
  crowdfunderStatuses: "crowdfundingMerged",
  crowdfunderTypes: "crowdfundingMerged",

  //#endregion

  //#region Communications
  categories: "communicationsMerged",
  categoryGroups: "communicationsMerged",
  channels: "communicationsMerged",
  consentSources: "communicationsMerged",
  correspondenceStatuses: "communicationsMerged",
  correspondenceTypes: "communicationsMerged",
  signalGroups: "communicationsMerged",
  signals: "communicationsMerged",
  preferences: "communicationsMerged",
  privacyStatementStatuses: "communicationsMerged",
  presets: "communicationsMerged",
  triggerStatuses: "communicationsMerged",
  //#endregion

  //#region Data Import
  DataImportBooleanValues: "importsMerged",
  DataImportDatePatterns: "importsMerged",
  DataImportDecimalSeparators: "importsMerged",
  DataImportEntityActions: "importsMerged",
  DataImportEntityStatuses: "importsMerged",
  DataImportEntityStages: "importsMerged",
  DataImportFeedStatuses: "importsMerged",
  DataImportFetchIntervals: "importsMerged",
  DataImportFieldTypes: "importsMerged",
  DataImportGiveWpApis: "importsMerged",
  DataImportHttpAuthenticationMethods: "importsMerged",
  DataImportHttpMethods: "importsMerged",
  DataImportMatchBys: "importsMerged",
  DataImportMatchingFailedActions: "importsMerged",
  DataImportMediaTypes: "importsMerged",
  DataImportMissingPropertyActions: "importsMerged",
  DataImportModifySubmissionActions: "importsMerged",
  DataImportQueryTypes: "importsMerged",
  DataImportQueueStatuses: "importsMerged",
  DataImportRecordAbandonedReasons: "importsMerged",
  DataImportRecordOnHoldReasons: "importsMerged",
  DataImportRecordStatuses: "importsMerged",
  DataImportRecordTypeGroups: "importsMerged",
  DataImportRecordTypes: "importsMerged",
  DataImportRunStages: "importsMerged",
  DataImportRunStatuses: "importsMerged",
  DataImportSourceTypes: "importsMerged",
  DataImportTextEncodings: "importsMerged",
  DataImportTextActions: "importsMerged",
  DataImportTransformStatuses: "importsMerged",
  DataImportUrlTemplatePlaceholders: "importsMerged",
  DataImportValueOrigins: "importsMerged",
  DataImportIssueTypes: "importsMerged",
  ImportEntityTypes: "importsMerged",
  //#endregion

  //#region Donations
  donationItems: "donationsMerged",
  donationsStatementStatuses: "donationsMerged",
  donationItemStatuses: "donationsMerged",
  donationTypes: "donationsMerged",
  givingStatuses: "donationsMerged",
  regularGivingFrequencies: "donationsMerged",
  donationStatuses: "donationsMerged",
  givingCancellationReasons: "donationsMerged",
  givingTypes: "donationsMerged",
  scheduleStatuses: "donationsMerged",
  //#endregion

  //#region Feedbacks
  feedbackCancellationReasons: "feedbacksMerged",
  feedbackCustomFieldTypes: "feedbacksMerged",
  feedbackProjectStatuses: "feedbacksMerged",
  feedbackSchemes: "feedbacksMerged",
  feedbackSchemeStatuses: "feedbacksMerged",
  feedbackTransactionTypes: "feedbacksMerged",
  feedbackStatuses: "feedbacksMerged",
  feedbackMilestones: "feedbacksMerged",
  //#endregion

  //#region Forms
  formFieldTypes: "formsMerged",
  formSchemas: "formsMerged",
  formStatuses: "formsMerged",
  formTypes: "formsMerged",
  formFieldLookupOptions: "formsMerged",
  //#endregion

  //#region Email Admin
  amazonRegions: "emailAdminMerged",
  deliveryServices: "emailAdminMerged",
  emailStatuses: "emailAdminMerged",
  emailTypes: "emailAdminMerged",
  trackingEvents: "emailAdminMerged",
  //#endregion

  //#region Lists
  connectionStatuses: "listsMerged",
  entityCategories: "listsMerged",
  listEntityTypes: "listsMerged",
  exportFormats: "listsMerged",
  priorityOrders: "listsMerged",
  listStatuses: "listsMerged",
  removeActions: "listsMerged",
  listPermissions: "listsMerged",
  rootTypes: "listsMerged",
  //#endregion

  //#region Mail
  addressFields: "mailMerged",
  addressSchemas: "mailMerged",
  //#endregion

  //#region Payments
  directDebitFileFields: "paymentsMerged",
  directDebitSubmissionStatuses: "paymentsMerged",
  directDebitSubmissionTypes: "paymentsMerged",
  collectionTypes: "paymentsMerged",
  directDebitFileNameMergeFields: "paymentsMerged",
  directDebitReportStatuses: "paymentsMerged",
  directDebitReportTypes: "paymentsMerged",
  declineTypes: "paymentsMerged",
  balanceTransactionTypes: "paymentsMerged",
  bankingOptions: "paymentsMerged",
  cardBrands: "paymentsMerged",
  processingDayRestrictions: "paymentsMerged",
  credentialStatuses: "paymentsMerged",
  deductionTypes: "paymentsMerged",
  depositStatuses: "paymentsMerged",
  leadTimeTypes: "paymentsMerged",
  paymentMethods: "paymentsMerged",
  paymentMethodStatuses: "paymentsMerged",
  paymentStatuses: "paymentsMerged",
  payoutStatuses: "paymentsMerged",
  paymentCategories: "paymentsMerged",
  refundMethods: "paymentsMerged",
  refundReasons: "paymentsMerged",
  refundStatuses: "paymentsMerged",
  refundTypes: "paymentsMerged",
  //#endregion

  //#region Print
  batchStatuses: "printMerged",
  queues: null, // Not available on /all endpoint
  //#endregion

  //#region Reporting
  InboxStatuses: "reportingMerged",
  ReportCancellationReasons: "reportingMerged",
  ReportRejectionReasons: "reportingMerged",
  ReportStatuses: "reportingMerged",
  ReportSubmissionTypes: "reportingMerged",
  ReportTypeStatuses: "reportingMerged",
  ReportFormats: "reportingMerged",
  SchemeReportingRequirementStatuses: "reportingMerged",
  //#endregion

  //#region Sponsorships
  beneficiaryAssignments: "sponsorshipsMerged",
  beneficiaryStatuses: "sponsorshipsMerged",
  beneficiaryTypes: "sponsorshipsMerged",
  sponsorshipCommitmentDurations: "sponsorshipsMerged",
  sponsorshipCommitmentTypes: "sponsorshipsMerged",
  sponsorshipEndedReasons: "sponsorshipsMerged",
  feedTypes: "sponsorshipsMerged",
  genders: "sponsorshipsMerged",
  schemeStatuses: "sponsorshipsMerged",
  sponsorshipNeedsAttentionReasons: "sponsorshipsMerged",
  sponsorshipStatuses: "sponsorshipsMerged",
  sponsorshipSchemes: "sponsorshipsMerged",
  sponsorshipTransactionTypes: "sponsorshipsMerged",
  sponsorshipCommitmentIntents: "sponsorshipsMerged",
  sponsorshipCohortStatuses: "sponsorshipsMerged",
  sponsorshipTransferListStatuses: "sponsorshipsMerged",
  sponsorshipCharityPartnerStatuses: "sponsorshipsMerged",
  sponsorshipPartnershipAgreementStatuses: "sponsorshipsMerged",
  sponsorshipTransferProfileStatuses: "sponsorshipsMerged",
  sponsorshipTransferValueSources: "sponsorshipsMerged",
  sponsorshipReportScopes: "sponsorshipsMerged",
  sponsorshipDeactivationStatuses: "sponsorshipsMerged",
  webhookActions: "sponsorshipsMerged",
  fetchStatuses: "sponsorshipsMerged",
  sponsorshipTransferListSuppressionConditions: "sponsorshipsMerged",
  //#endregion

  //#region Pledges
  pledgeCancellationReasons: "pledgesMerged",
  pledgeSources: "pledgesMerged",
  pledgeStatuses: "pledgesMerged",
  pledgeTransactionTypes: "pledgesMerged",
  pledgeResolveActions: "pledgesMerged",
  pledgeBalanceTransferTypes: "pledgesMerged",
  //#endregion

  //#region SMS
  smsDeliveryServices: "smsMerged",
  smsStatuses: "smsMerged",
  smsTrackingEvents: "smsMerged",
  templatePlaceholders: "smsMerged",
  textMarketerEnvironments: "smsMerged",
  //#endregion

  //#region Subscriptions
  aggregationFunctions: "subscriptionsMerged",
  allocationTypes: "subscriptionsMerged",
  applications: "subscriptionsMerged",
  countries: "subscriptionsMerged",
  customerStatuses: "subscriptionsMerged",
  dataSharingPreferences: "subscriptionsMerged",
  displayOptions: "subscriptionsMerged",
  dataTypes: "subscriptionsMerged",
  dateCriterionOptions: "subscriptionsMerged",
  dateFormats: "subscriptionsMerged",
  dateTimeCriterionOptions: "subscriptionsMerged",
  daysOfMonth: "subscriptionsMerged",
  daysOfWeek: "subscriptionsMerged",
  decimalCriterionOptions: "subscriptionsMerged",
  integerCriterionOptions: "subscriptionsMerged",
  languages: "subscriptionsMerged",
  lookupCriterionOptions: "subscriptionsMerged",
  markupLanguages: "subscriptionsMerged",
  numberFormats: "subscriptionsMerged",
  onlinePresenceTypes: "subscriptionsMerged",
  phoneNumberTypes: "subscriptionsMerged",
  prospectiveOptions: "subscriptionsMerged",
  retrospectiveOptions: "subscriptionsMerged",
  subscriberTypes: "subscriptionsMerged",
  subscriptionStatuses: "subscriptionsMerged",
  textMatchingModes: "subscriptionsMerged",
  timeCriterionOptions: "subscriptionsMerged",
  timeFormats: "subscriptionsMerged",
  timePeriodTypes: "subscriptionsMerged",
  timezones: "subscriptionsMerged",
  unitsOfTime: "subscriptionsMerged",
  validities: "subscriptionsMerged",
  workbookFormats: "subscriptionsMerged",
  dataPullConnectionTypes: "subscriptionsMerged",
  dataPushConnectionTypes: "subscriptionsMerged",
  //#endregion

  //#region Tax Relief
  claimTypes: "taxReliefMerged",
  claimStatuses: "taxReliefMerged",
  donationAbandonedReasons: "taxReliefMerged",
  donationOnHoldReasons: "taxReliefMerged",
  submissionStatuses: "taxReliefMerged",
  taxReliefDonationStatuses: "taxReliefMerged",
  declarationStatuses: "taxReliefMerged",
  ineligibleReasons: "taxReliefMerged",
  taxReliefRates: "taxReliefMerged",
  taxStatusSources: "taxReliefMerged",
  taxYears: "taxReliefMerged",
  hmrcEnvironments: "taxReliefMerged",
  taxSchemeStatuses: "taxReliefMerged",
  regulators: "taxReliefMerged",
  taxReliefProcessors: "taxReliefMerged",
  identificationTypes: "taxReliefMerged",
  natureOfPersons: "taxReliefMerged",
  //#endregion

  //#region Templates
  assetTypes: "templatesMerged",
  compositionTypes: "templatesMerged",
  contentVisibilities: "templatesMerged",
  fragmentTypes: "templatesMerged",
  letterheadStatuses: "templatesMerged",
  media: "templatesMerged",
  outputFormats: "templatesMerged",
  pageOrientations: "templatesMerged",
  pageSizes: "templatesMerged",
  templateEngines: "templatesMerged",
  templateModelTypes: "templatesMerged",
  templateStatuses: "templatesMerged",
  //#endregion

  //#region Users
  permissions: "usersMerged",
  userStatuses: "usersMerged",
  //#endregion

  //#region Tasks
  linkedEntityTypes: "tasksMerged",
  taskPriorities: "tasksMerged",
  taskStatuses: "tasksMerged",
  //#endregion

  //#region WebHooks
  endpointStatuses: "webhooksMerged",
  eventTypeCategories: "webhooksMerged",
  eventTypeGroups: "webhooksMerged",
  eventTypes: "webhooksMerged",
  //#endregion
};

/*
 * Some components require multiple lookups.
 * Define collections of lookups as arrays below.
 */
export const accountsLookups: LookupType[] = [
  AccountLookups.AttachmentTypes,
  CommunicationsLookups.Categories,
  CommunicationsLookups.CategoryGroups,
  CommunicationsLookups.Channels,
  CommunicationsLookups.ConsentSources,
  SubscriptionLookups.Countries,
  TaxReliefLookups.TaxStatusSources,
  TaxReliefLookups.IneligibleReasons,
  EmailLookups.EmailTypes,
  SubscriptionLookups.OnlinePresenceTypes,
  AccountLookups.OrganizationTypes,
  SubscriptionLookups.PhoneNumberTypes,
  CommunicationsLookups.Preferences,
  CommunicationsLookups.Presets,
  AccountLookups.Titles,
];

export const pledgesLookups: LookupType[] = [
  PledgeLookups.PledgeCancellationReasons,
  PledgeLookups.PledgeSources,
  PledgeLookups.PledgeStatuses,
  PledgeLookups.PledgeTransactionTypes,
  PledgeLookups.PledgeResolveActions,
];

export const analyticsLookups: LookupType[] = [
  AnalyticsLookups.ReportCategories,
];

export const measuresLookups: LookupType[] = [
  AnalyticsLookups.ReportCategories,
  AnalyticsLookups.MeasureCategories,
  SubscriptionLookups.UnitsOfTime,
  SubscriptionLookups.AggregationFunctions,
  SubscriptionLookups.TimePeriodTypes,
];

export const analyticsAdminLookups: LookupType[] = [
  AnalyticsLookups.ReportCategories,
  AnalyticsLookups.ReportTypes,
];

export const viewAccountBasicInfoLookups: LookupType[] = [
  AccountLookups.OrganizationTypes,
  AccountLookups.Titles,
];

export const accountAddressFormLookups: LookupType[] = [
  SubscriptionLookups.Countries,
];

export const closeAccountFormLookups: LookupType[] = [
  AccountLookups.ClosureReasons,
];

export const viewAccountContactInfoLookups: LookupType[] = [
  SubscriptionLookups.Countries,
  EmailLookups.EmailTypes,
  SubscriptionLookups.PhoneNumberTypes,
];

export const viewAccountOnlinePresenceLookups: LookupType[] = [
  SubscriptionLookups.OnlinePresenceTypes,
];

export const viewAccountContactPreferencesLookups: LookupType[] = [
  CommunicationsLookups.Categories,
  CommunicationsLookups.CategoryGroups,
  CommunicationsLookups.Channels,
  CommunicationsLookups.ConsentSources,
  CommunicationsLookups.Preferences,
  CommunicationsLookups.Presets,
];

export const userFormLookups: LookupType[] = [
  SubscriptionLookups.DateFormats,
  SubscriptionLookups.Languages,
  SubscriptionLookups.NumberFormats,
  UserLookups.Permissions,
  UserLookups.UserStatuses,
  SubscriptionLookups.TimeFormats,
  SubscriptionLookups.Timezones,
];

export const organizationInformationLookups: LookupType[] = [
  SubscriptionLookups.Countries,
  SubscriptionLookups.SubscriberTypes,
];

export const subsLocalizationLookups: LookupType[] = [
  SubscriptionLookups.Countries,
  SubscriptionLookups.DateFormats,
  SubscriptionLookups.Languages,
  SubscriptionLookups.NumberFormats,
  SubscriptionLookups.SubscriberTypes,
  SubscriptionLookups.TimeFormats,
  SubscriptionLookups.Timezones,
];

export const userPreferencesLookups: LookupType[] = [
  SubscriptionLookups.Countries,
  SubscriptionLookups.DateFormats,
  SubscriptionLookups.Languages,
  SubscriptionLookups.NumberFormats,
  SubscriptionLookups.TimeFormats,
  SubscriptionLookups.Timezones,
];

export const userNavLookups: LookupType[] = [
  SubscriptionLookups.Timezones,
  SubscriptionLookups.Languages,
];

export const donationItemsLookups: LookupType[] = [
  SubscriptionLookups.Countries,
];

export const pledgeSourcesLookups: LookupType[] = [PledgeLookups.PledgeSources];

export const pledgeCancellationReasonsLookups: LookupType[] = [
  PledgeLookups.PledgeCancellationReasons,
];

export const pledgesTableLookups: LookupType[] = [
  PledgeLookups.PledgeSources,
  PledgeLookups.PledgeStatuses,
];

export const donationDetailsPageLookups: LookupType[] = [
  PaymentsLookups.PaymentStatuses,
];

export const SponsorshipAllocationFormLookups: LookupType[] = [
  SponsorshipLookups.SponsorshipCommitmentDurations,
  SubscriptionLookups.Countries,
];

export const sponsorshipSchemesLookups: LookupType[] = [
  SponsorshipLookups.BeneficiaryAssignments,
  SponsorshipLookups.BeneficiaryTypes,
  SponsorshipLookups.SponsorshipCommitmentDurations,
  SponsorshipLookups.SponsorshipCommitmentTypes,
  SponsorshipLookups.FeedTypes,
  SponsorshipLookups.SponsorshipStatuses,
];

export const sponsorshipsSearchFormLookups: LookupType[] = [
  SponsorshipLookups.BeneficiaryTypes,
  SponsorshipLookups.SponsorshipCommitmentDurations,
  SponsorshipLookups.SponsorshipCommitmentTypes,
  SponsorshipLookups.SponsorshipStatuses,
];

export const sponsorshipsDetailsLookups: LookupType[] = [
  SponsorshipLookups.SponsorshipSchemes,
  SponsorshipLookups.SponsorshipCommitmentTypes,
  SponsorshipLookups.SponsorshipStatuses,
  SponsorshipLookups.SponsorshipNeedsAttentionReasons,
  DonationLookups.DonationStatuses,
  ReportLookups.ReportStatuses,
];

export const sponsorshipsAdvancedSearch: LookupType[] = [
  SponsorshipLookups.SponsorshipSchemes,
  SponsorshipLookups.SponsorshipStatuses,
  SponsorshipLookups.SponsorshipNeedsAttentionReasons,
];
export const sponsorshipsList: LookupType[] = [
  SponsorshipLookups.SponsorshipSchemes,
  SponsorshipLookups.SponsorshipCommitmentTypes,
  SponsorshipLookups.SponsorshipStatuses,
  SponsorshipLookups.SponsorshipNeedsAttentionReasons,
  ReportLookups.ReportStatuses,
];

export const sponsorshipsEndTransferLookups: LookupType[] = [
  SponsorshipLookups.SponsorshipEndedReasons,
];

export const sponsorshipsOrphanPackLookups: LookupType[] = [
  SubscriptionLookups.Countries,
];

export const dataSourcesLookups: LookupType[] = [
  SubscriptionLookups.Countries,
  DataImportLookups.DataImportDatePatterns,
  DataImportLookups.DataImportHttpMethods,
  DataImportLookups.DataImportHttpAuthenticationMethods,
  DataImportLookups.DataImportFetchIntervals,
  DataImportLookups.DataImportMatchBys,
  DataImportLookups.DataImportGiveWpApis,
  DataImportLookups.DataImportDecimalSeparators,
  DataImportLookups.DataImportTextEncodings,
  DataImportLookups.ImportEntityTypes,
  DataImportLookups.DataImportEntityActions,
  DataImportLookups.DataImportMatchingFailedActions,
  DataImportLookups.DataImportMediaTypes,
  DataImportLookups.DataImportMissingPropertyActions,
  DataImportLookups.DataImportRecordTypes,
  DataImportLookups.DataImportSourceTypes,
  DataImportLookups.DataImportUrlTemplatePlaceholders,
  SubscriptionLookups.TextMatchingModes,
];

export const paymentsAdminLookups: LookupType[] = [
  PaymentsLookups.ProcessingDayRestrictions,
  SubscriptionLookups.DaysOfMonth,
  SubscriptionLookups.DaysOfWeek,
  SubscriptionLookups.DisplayOptions,
  PaymentsLookups.LeadTimeTypes,
];

export const batchesSearchFormLookups: LookupType[] = [
  PrintLookups.BatchStatuses,
  "queues",
];

export const donationsSearchLookups: LookupType[] = [
  DonationLookups.DonationStatuses,
  DonationLookups.DonationTypes,
];

export const viewRegularGivingLookups: LookupType[] = [
  DonationLookups.GivingStatuses,
  DonationLookups.RegularGivingFrequencies,
  DonationLookups.GivingCancellationReasons,
  SubscriptionLookups.DaysOfWeek,
];

export const regularDonationLookups: LookupType[] = [
  DonationLookups.GivingStatuses,
  DonationLookups.RegularGivingFrequencies,
];

export const feedbacksAdvancedSearch: LookupType[] = [
  FeedbackLookups.FeedbackSchemes,
  FeedbackLookups.FeedbackStatuses,
];

export const feedbacksSearchFormLookups: LookupType[] = [
  FeedbackLookups.FeedbackStatuses,
];

export const feedbacksCancellationReasonsLookups: LookupType[] = [
  FeedbackLookups.FeedbackCancellationReasons,
];

export const feedbacksSchemesLookups: LookupType[] = [
  FeedbackLookups.FeedbackSchemeStatuses,
];

export const givingsAdvancedSearchLookups: LookupType[] = [
  DonationLookups.GivingStatuses,
  DonationLookups.RegularGivingFrequencies,
  PaymentsLookups.PaymentMethods,
  SubscriptionLookups.DaysOfMonth,
  SubscriptionLookups.DaysOfWeek,
];
